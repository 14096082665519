import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/AuthService';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  form: FormGroup;
  showPassword = true;
  showPasswordC = true;
  isSubmitted = false;
  loading = false;
  errorMessage: string | undefined;
  errorMessageDU = '';

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.translate.setDefaultLang(this.languageService.getLanguage());

    this.form = this.formBuilder.group({
      Name: [' '],
      Email: [''],
      Company: [' '],
      Password: [''],
      ConfirmPassword: [''],
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      Name: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.email]],
      Company: ['', [Validators.required]],
      Password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[\W_])[A-Za-z\d\W_]+$/),
        ],
      ],
      ConfirmPassword: ['', [Validators.required]],
    });
  }

  get errorControl() {
    return this.form.controls;
  }

  resetErrorMessage(): void {
    this.errorMessageDU = '';
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }

    if (this.form.status == 'VALID') {
      const name = this.form.controls['Name'].value;
      const email = this.form.controls['Email'].value;
      const company = this.form.controls['Company'].value;
      const password = this.form.controls['Password'].value;
      const confirmPassword = this.form.controls['ConfirmPassword'].value;
      const portal_id = 1;

      if (password !== confirmPassword) {
        this.errorMessage = 'Passwords and Confirm Password must match';
        this.form.setErrors({ passwordMismatch: true });
        return;
      }

      const requestBody = {
        name: name,
        email: email,
        company_name: company,
        password: password,
        portal_id: portal_id,
      };

      this.loading = true;
      this.authService.signup(requestBody).subscribe(
        (response) => {
          if (response.data.status !== '500') {
            // first clear local storage
            localStorage.clear();
            // console.log('Signup successful:', response);
            const user = response.user;
            const token = response.token;
            // this.authService.saveUserDetails(user);
            // this.authService.saveToken(token);
            this.nextMove();
          } else {
            console.log('Signup Failed:', response.data.logs);
            this.errorMessageDU = response.data.logs;
          }
          this.isSubmitted = false;
          this.loading = false;
        },
        (error) => {
          console.error('Signup error:', error);
          this.loading = false;
        }
      );
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  togglePasswordC() {
    this.showPasswordC = !this.showPasswordC;
  }

  nextMove() {
    // const tag = 'SignUpPayment';
    // this.authService.setTag(tag).subscribe(
    //   (response) => {
    //     console.log('Tag set Response:', response);
    //   },
    //   (error) => {
    //     console.error('Tag set Error:', error);
    //   }
    // );

    // this.authService.setChooseSubscription();
    this.router.navigate(['/sign-in']);
  }
}
