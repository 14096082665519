<!-- <div class="container-fluid p-0">
    <div class="row gx-0">
        <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
            <div class="col-12 col-md-4 col-lg-3">
                <div class="col-12 p-4 left-back d-flex" style="justify-content: center; flex-wrap: wrap;">
                    <div class="col-12 d-flex" style="justify-content: start; align-items: start;">
                        <img src="../../../assets/Logo-1-White.png" width="130px" height="60px">
                    </div>
                    <div class="col-12 pt-5 pt-md-0 d-flex"
                        style="justify-content: center; align-items: center; flex-wrap: wrap;">
                        <div class="col-12">
                            <p class="head" style="color: #FFFFFF;">Een paar klikken verwijderd van het maken van een
                                geweldige chatbot</p>
                            <p class="desc pt-1" style="color: #FFFFFF;">Activeer uw chatbot in minuten en bespaar tijd.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 pt-5 pt-md-0 d-flex" style="justify-content: center; align-items: end;">
                        <div class="col-12 inst-tab">

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8 col-lg-9">
                <div class="col-12 p-4 right-back d-flex" style="justify-content: center; flex-wrap: wrap;">
                    <div class="col-12 desc d-flex"
                        style="justify-content: end; align-items: start; letter-spacing: 0px;">
                        Heb je hulp nodig?
                        <span class="ms-1" style="color: #775da6; cursor: pointer; font-weight: 700;">
                            Help mij</span>
                    </div>
                    <div class="col-12 col-md-md-8 col-lg-7 col-xl-6 d-flex"
                        style="justify-content: center; align-items: start; flex-wrap: wrap;">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="col-12 d-flex"
                                style="justify-content: center; align-items: start; flex-wrap: wrap;">
                                <div class="col-12 desc">
                                    Heb al een account!
                                    <span class="ms-1" style="color: #775da6; cursor: pointer; font-weight: 700;"
                                        routerLink="/sign-in">
                                        Login</span>
                                    <span class="ms-1">hier</span>
                                </div>
                                <div class="col-12 pt-4 head">
                                    Welkom!
                                </div>
                                <div class="col-12 desc">Sign Up to Dialect</div>
                                <div class="col-12 pt-4 d-flex" style="flex-wrap: wrap; justify-content: center;">
                                    <div class="col-12">
                                        <input matInput class="form-control" formControlName="Name" name="Name"
                                            type="text" placeholder="Naam">
                                    </div>
                                    <div class="col-12 pt-3">
                                        <input matInput class="form-control" formControlName="Email" name="Email"
                                            type="email" placeholder="Email">
                                    </div>
                                    <div class="col-12 pt-3">
                                        <input matInput class="form-control" formControlName="Company" name="Company"
                                            type="text" placeholder="Bedrijf">
                                    </div>
                                    <div class="col-12 pt-3" style="position: relative;">
                                        <input matInput class="form-control" formControlName="Password" name="Password"
                                            [type]="showPassword ? 'password' : 'text'" placeholder="Wachtwoord"
                                            style="padding-right: 40px;">
                                        <img (click)="togglePassword()" class="m-2 eye"
                                            [src]="showPassword ? '../../assets/eye-icon.svg' : '../../assets/eye-slash.svg'"
                                            alt="Eye Icon">
                                    </div>
                                    <div class="col-12 pt-3" style="position: relative;">
                                        <input matInput class="form-control" formControlName="ConfirmPassword"
                                            name="ConfirmPassword" [type]="showPasswordC ? 'password' : 'text'"
                                            placeholder="bevestig wachtwoord" style="padding-right: 40px;">
                                        <img (click)="togglePasswordC()" class="m-2 eye"
                                            [src]="showPasswordC ? '../../assets/eye-icon.svg' : '../../assets/eye-slash.svg'"
                                            alt="Eye Icon">
                                    </div>
                                    <div class="col-12 pt-3 d-flex" style="justify-content: start;">
                                        <div class="col-6">
                                            <button class="btn p-2" routerLink="/" type="submit">Sign Up</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 desc pt-5 d-flex"
                                    style="justify-content: space-between; align-items: center;">
                                    <div class="col-5">
                                        <hr style="border: 1px solid #DFDFDF;">
                                    </div>
                                    <span style="text-align: center;">Sign Up with</span>
                                    <div class="col-5">
                                        <hr style="border: 1px solid #DFDFDF;">
                                    </div>
                                </div>
                                <div class="col-12 desc pt-3 d-flex"
                                    style="justify-content: space-between; align-items: center;">
                                    <div class="col-5 d-flex" style="justify-content: center;">
                                        <img class="social-icon" src="../../../assets/google-icon.svg" alt="Google">
                                    </div>
                                    <span
                                        style="text-align: center; font-size: calc(var(--font-extra-small));">OR</span>
                                    <div class="col-5 d-flex" style="justify-content: center;">
                                        <img class="social-icon" src="../../../assets/facebook-icon.svg" alt="Facebook">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="container-fluid">
    <div class="row right-back">
        <div class="col-12 d-flex" style="justify-content: center; align-items: end; flex-wrap: wrap; flex: 1;">
            <div class="col-12 col-md-8 form mt-5 mb-5 d-flex"
                style="justify-content: center; align-items: center; flex-wrap: wrap;">
                <div class="col-12 d-flex" style="justify-content: center;">
                    <img class="logo" routerLink="/" src="../../../assets/Logo-1-dark.png" alt="Dialect">
                </div>
                <div class="col-12 pt-3 head">
                    {{ "SignUp.Welcome! to Dialect-AI" | translate }}
                </div>
                <div class="col-12 desc pt-1">
                    {{ "SignUp.Start managing your business here by creating chatbots" | translate }}
                </div>
                <div class="col-12 pt-4 d-flex" style="justify-content: center;">
                    <div class="col-12 col-md-12">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                <div class="col-12 d-flex" style="position: relative; align-items: center;">
                                    <img class="img" src="../../../assets/useName-icon.svg" alt="useName-icon">
                                    <input matInput class="form-control" formControlName="Name" name="Name" type="text"
                                        placeholder="{{ 'Placeholder.Name' | translate }}"
                                        (click)="resetErrorMessage()">
                                </div>
                                <div class="col-12 d-flex" style="justify-content: start;">
                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                        *ngIf="isSubmitted && errorControl['Name']['errors']?.['required']">
                                        {{ "SignUp.Name is required" | translate }}
                                    </mat-error>
                                </div>
                                <div class="col-12 mt-4 d-flex" style="position: relative; align-items: center;">
                                    <img class="img" src="../../../assets/email-icon.svg" alt="Email">
                                    <input matInput class="form-control" formControlName="Email" name="Email"
                                        type="email" placeholder="{{ 'Placeholder.Username or email' | translate }}"
                                        (click)="resetErrorMessage()">
                                </div>
                                <div class="col-12 d-flex" style="justify-content: start;">
                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                        *ngIf="isSubmitted && errorControl['Email']['errors']?.['required']">
                                        {{ "SignUp.Email is required" | translate }}
                                    </mat-error>
                                </div>
                                <div class="col-12 mt-4 d-flex" style="position: relative; align-items: center;">
                                    <img class="img" src="../../../assets/company-icon.svg" alt="Company">
                                    <input matInput class="form-control" formControlName="Company" name="Company"
                                        type="text" placeholder="{{ 'Placeholder.Company' | translate }}"
                                        (click)="resetErrorMessage()">
                                </div>
                                <div class="col-12 d-flex" style="justify-content: start;">
                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                        *ngIf="isSubmitted && errorControl['Company']['errors']?.['required']">
                                        {{ "SignUp.Company is required" | translate }}
                                    </mat-error>
                                </div>
                                <div class="col-12 mt-4 d-flex" style="position: relative; align-items: center;">
                                    <img class="img" src="../../../assets/password-icon.svg" alt="password-icon">
                                    <input matInput class="form-control" formControlName="Password" name="Password"
                                        [type]="showPassword ? 'password' : 'text'"
                                        placeholder="{{ 'Placeholder.Password' | translate }}"
                                        style="padding-right: 40px;" (click)="resetErrorMessage()">
                                    <img (click)="togglePassword()" class="eye"
                                        [src]="showPassword ? '../../assets/eye-icon.svg' : '../../assets/eye-slash.svg'"
                                        alt="Eye Icon">
                                </div>
                                <div class="col-12 d-flex" style="justify-content: start;">
                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                        *ngIf="isSubmitted && errorControl['Password']['errors']?.['required']">
                                        {{ "SignUp.Password is required.a" | translate }}
                                    </mat-error>
                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                        *ngIf="isSubmitted && errorControl['Password']['errors']?.['pattern']">
                                        {{ "SignUp.Password is required.b" | translate }}
                                    </mat-error>
                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                        *ngIf="isSubmitted && errorControl['Password']['errors']?.['minlength'] && !errorControl['Password']['errors']?.['pattern']">
                                        {{ "SignUp.Password is required.c" | translate }}
                                    </mat-error>
                                </div>
                                <div class="col-12 mt-4 d-flex" style="position: relative; align-items: center;">
                                    <img class="img" src="../../../assets/password-icon.svg" alt="password-icon">
                                    <input matInput class="form-control" formControlName="ConfirmPassword"
                                        name="ConfirmPassword" [type]="showPasswordC ? 'password' : 'text'"
                                        placeholder="{{ 'Placeholder.Confirm Password' | translate }}"
                                        style="padding-right: 40px;" (click)="resetErrorMessage()">
                                    <img (click)="togglePasswordC()" class="eye"
                                        [src]="showPasswordC ? '../../assets/eye-icon.svg' : '../../assets/eye-slash.svg'"
                                        alt="Eye Icon">
                                </div>
                                <div class="col-12 d-flex" style="justify-content: start;">
                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                        *ngIf="isSubmitted && errorControl['ConfirmPassword']['errors']?.['required']">
                                        {{ "SignUp.Confirm Password is required" | translate }}
                                    </mat-error>
                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                        *ngIf="isSubmitted && form.hasError('passwordMismatch')">
                                        {{ errorMessage }}
                                    </mat-error>
                                    <mat-error class="pt-3" style="font-size: calc(var(--font-medium));">
                                        {{ errorMessageDU }}
                                    </mat-error>
                                </div>
                                <div class="col-12 pt-4 d-flex" style="justify-content: center;">
                                    <button class="btn p-2" type="submit" [hidden]="loading" [disabled]="loading">
                                        {{ "SignUp.Sign Up" | translate }}
                                    </button>
                                    <mat-spinner [hidden]="!loading" diameter="40" strokeWidth="5"></mat-spinner>
                                </div>
                                <div class="col-12 pt-4 desc">
                                    {{ "SignUp.Do you already have an account?" | translate }}
                                    <span class="ms-1" routerLink="/sign-in"
                                        style="color: #775da6; cursor: pointer; font-weight: 700;">Sign in</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 d-flex" style="align-items: end; flex: 1;">
            <div class="col-12 footer" style="flex: 1;">
                <app-footer></app-footer>
            </div>
        </div>
    </div>
</div>